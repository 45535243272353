@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

body {
    overscroll-behavior: none;
   
    margin: auto;
    display: flex;
    flex-direction: column;
    min-height: 100vh; /* Use viewport height to ensure it covers at least the entire screen */

  }
